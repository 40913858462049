import { Dialog, DialogTitle } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { AuthBtn, MainBtn } from '../../components/Buttons';
import { useTranslation } from 'react-i18next';

const QuizCompletionModal = ({ handleClose, open, scores, handleOk }) => {
  const winner = (scores.numberOfCorrectAnswers ?? 0) > 0;
  const { t } = useTranslation();

  return (
    <Dialog open={open} onSubmit={handleClose}>
      <DialogTitle>
        <div>
          {winner ? '🎉' : '😔'}
          <span>{t('quiz.resultTitle')}</span>
        </div>
      </DialogTitle>
      <div className="quiz-modal-content">
        {
          winner ?
            <p>
              {t('quiz.succesQuizzMessage1')}
              <span
                style={{
                  fontWeight: 700,
                  color: winner ? 'green' : 'red'
                }}>{`${scores.numberOfCorrectAnswers}/${scores.numberOfQuestions}`}</span>{' '}
              {t('quiz.succesQuizzMessage2')}
            </p>
            :
            <p className={'mt-2'}>{t('quiz.errorQuizzMessage')}</p>
        }
      </div>

      <div className="quiz-footer">
        <MainBtn title={t('quiz.result_cancel')} onClick={handleClose} />
        <AuthBtn
          isDisabled={false}
          isLoading={false}
          title={ winner ? t('quiz.result_ok') : t('quiz.blog')}
          onClick={handleOk}
          className={'save-btn'}
        />
      </div>
    </Dialog>
  );
};

QuizCompletionModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
    scores: PropTypes.shape({
        numberOfCorrectAnswers: PropTypes.number,
        numberOfQuestions: PropTypes.number
    }).isRequired,
  handleOk: PropTypes.func.isRequired,
  open: PropTypes.func.isRequired
};

export default QuizCompletionModal;
