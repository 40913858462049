export const API_BASE_URL = 'https://prodapi.snapfood.al/api/';
//export const API_BASE_URL = 'https://stageapi.snapfood.al/api/';
//export const API_BASE_URL = 'http://10.10.17.143:8000/api/';
export const IMG_BASE_URL = 'https://snapfoodal.imgix.net/';
export const USER_PROFILE_IMG_BASE_URL = 'https://sf-users.s3.eu-central-1.amazonaws.com/';
export const APP_KEY = 'SNAPFOOD-3exeAtM4CMRAKNWNdza92QyP4';
export const GOOGLE_MAP_API_KEY = 'AIzaSyAEe-vcJ-r8w9FQdVEskAozi1v9cWy6YAA';
export const GA_TRACKING_ID = 'G-85S49JG07E';
export const PLATFORM = 'Web';
export const DEFAULT_LANG = 'sq';
export const DEFAULT_SEO_LANG = 'en';
export const DEFAULT_SEO_META_DESCRIPTION =
  'Snapfood është një hapësirë e përbashkët virtuale për të shijuar ushqim të mirë, për t\'u argëtuar me miqtë dhe për të krijuar kujtime të bukura së bashku.';

export const OrderType_Delivery = 'Delivery';
export const OrderType_Pickup = 'Pickup';
export const OrderType_Reserve = 'Reserve';

export const VendorType_Restaurant = 'Restaurant';
export const VendorType_Grocery = 'Grocery';

export const Pay_COD = 'Cash On Delivery';
export const Pay_Card = 'Pay With Card';
export const Pay_Paypal = 'Paypal';
export const Pay_Apple = 'Apple Pay';

export const VSort_Title = 'title';
export const VSort_FastDelivery = 'maximum_delivery_time';
export const VSort_HighRating = 'rating_interval';
export const VSort_Closest = 'distance';
export const VSort_Low2HighPrice = 'delivery_minimum_order_price';
export const VSort_PopularFirst = 'total_ratings';

export const Order_Pending = 0;
export const Order_Reserved = 1;
export const Order_Preparing = 1;
export const Order_Accepted = 1;
export const Order_Outfordelivery = 2;
export const Order_Ready_pickup = 2;
export const Order_Delivered = 3;
export const Order_Pickedup = 3;
export const Order_Completed = 2;
export const Order_Cancelled = -1;

export const ROLE_ADMIN = 'admin';
export const ROLE_FINANCE = 'finance';
export const ROLE_RESTAURANT = 'vendor';
export const ROLE_RIDER = 'delivery';
export const ROLE_CUSTOMER = 'customer';

export const SNAPFOOD_AVATAR =
  'https://firebasestorage.googleapis.com/v0/b/snapfood-3f623.appspot.com/o/icon.png?alt=media&token=2840acb2-a2b5-465c-9093-e3bdf4269d1c';
export const ORDER_SUPPORT_ADMIN_MSG = 'Shkruaj çdo pyetje apo kërkesë për porosinë tënde!';

export const SNAP_FOOD_ADMIN_CONTACT = {
  id: 'SNAP_FOOD_ADMIN',
  username: 'Snapfood Support',
  full_name: 'Snapfood Support',
  photo: SNAPFOOD_AVATAR,
  phone: '',
  email: 'snapfoodinternational@gmail.com',
  avatar: SNAPFOOD_AVATAR,
  role: ROLE_ADMIN
};

export const TIRANA_CITY_LOCATION = {
  latitude: 41.32754,
  longitude: 19.81869,
  country: 'Shqiperi',
  city: 'Tirana',
  street: ''
};
export const SocialMapScreenStyles = [
  {
    elementType: 'geometry',
    stylers: [
      {
        color: '#f5f5f5'
      }
    ]
  },
  {
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161'
      }
    ]
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#f5f5f5'
      }
    ]
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#bdbdbd'
      }
    ]
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eeeeee'
      }
    ]
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575'
      }
    ]
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e5e5e5'
      }
    ]
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e'
      }
    ]
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffffff'
      }
    ]
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575'
      }
    ]
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [
      {
        color: '#dadada'
      }
    ]
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161'
      }
    ]
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e'
      }
    ]
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e5e5e5'
      }
    ]
  },
  {
    featureType: 'transit.station',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eeeeee'
      }
    ]
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#c9c9c9'
      }
    ]
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e'
      }
    ]
  }
];
