/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Seo from '../../components/Seo';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import { ChevronLeft } from '@styled-icons/boxicons-regular';
import Svg_share from '../../assets/svgs/blog/share.svg';
import './details.css';
import { Config, ROUTES_NAMES } from '../../constants';
import apiFactory from '../../services/apiFactory';
import { confirmAlert } from 'react-confirm-alert';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { setBlogData } from '../../store/actions/app';
import { decodeHash } from '../../utils/common';
import { RWebShare } from 'react-web-share';
import { identifyDevice, trackEvent } from '../../utils/mixpanelUtil';
import QuizComponent from '../quiz/quiz';
import { quizService } from '../../services/quizService';
import QuizCompletionModal from '../../components/Modals/quiz-completition-modal';
import Subscribe from '../../components/Subscribe';

// eslint-disable-next-line no-undef
const { v4: uuidv4 } = require('uuid');

const BlogDetails = (props) => {
  const navigate = useNavigate();
  const { app } = useSelector((state) => state);
  const { t } = useTranslation();
  const { blog_hash } = useParams();
  const blog_id = decodeHash(blog_hash);
  const user_id = uuidv4();

  const [questions, setQuestions] = useState();
  const [results, setResults] = useState();
  const [displayQuiz, setDisplayQuiz] = useState(true);

  useEffect(() => {
    if (blog_id) {
      apiFactory
        .get(`blogs/${blog_id}`)
        .then(({ data }) => {
          props.setBlogData(data.blog);
          identifyDevice();
          trackEvent('Blog Details', {
            action_category: 'Details Screen',
            action_outcome: 'success',
            interaction_element: 'screen',
            blog_detail_id: blog_id,
            source: 'sf_web'
          });
        })
        .catch((error) => {
          const message = error.message || t('generic_error');
          confirmAlert({
            title: t('alerts.error'),
            message: message,
            closeOnEscape: true,
            closeOnClickOutside: true,
            buttons: [
              {
                label: t('Ok'),
                className: 'error-ok-btn',
                onClick: () => {}
              }
            ]
          });
          identifyDevice();
          trackEvent('Blog Details', {
            action_category: 'Details Screen',
            action_outcome: 'success',
            interaction_element: 'fail',
            blog_detail_id: blog_id,
            source: 'sf_web'
          });
        });
    }
  }, [blog_id]);

  const addBlogCount = () => {
    const currentBlog = props.blog_data;
    const payload = {
      blog_title: currentBlog.title,
      blog_content: currentBlog.content,
      blog_word_count: currentBlog.content.length,
      author: currentBlog.author,
      read_amount: parseInt(currentBlog?.time_read ?? 0),
      blog_id: currentBlog.id
    };
    quizService()
      .blogCount(payload)
      .then((res) => {
        setQuestions(res.data);
        identifyDevice();
        trackEvent('Blog Details', {
          action_category: 'Quiz Screen',
          action_outcome: 'success',
          interaction_element: 'screen',
          blog_detail_id: blog_id,
          source: 'sf_web'
        });
      })
      .catch(() => {
        setQuestions(undefined);
        trackEvent('Blog Details', {
          action_category: 'Quiz Screen',
          action_outcome: 'fail',
          interaction_element: 'screen',
          blog_detail_id: blog_id,
          source: 'sf_web'
        });
      });
  };

  const isUserLogged = useMemo(() => {
    if (app && app.isLoggedIn) {
      return true;
    }

    return false;
  }, [app]);

  const blogs = useMemo(() => JSON.parse(localStorage.getItem('showQuiz')) ?? [], []);

  const userDetails = useMemo(() => {
    if (blogs) {
      const blogExists = blogs.some(
        (entry) => String(entry.blog_id) === String(props.blog_data.id)
      );
      const sumEarned = blogs.reduce((acc, entry) => acc + Number(entry.earned_from_quiz), 0);
      return { blogExists, sumEarned };
    }
    return { blogExists: false, sumEarned: 0 };
  }, [blogs, props.blog_data]);

  const answerArray = (quiz) => {
    return quiz.questions.map((quizQuestion, index) => {
      const question = questions.quiz.quiz.questions[index];
      const userAnswerIndex = quiz.userInput[index] - 1;
      const userAnswer = quizQuestion.answers[userAnswerIndex];
      const correctAnswer = question.answers.find((answer) => answer.answer_text === userAnswer);

      return {
        quiz_id: questions.quiz.quiz.id,
        question_id: question.id,
        answer_id: correctAnswer.id
      };
    });
  };

  const showQuiz = useMemo(() => {
    if (!questions || questions.can_show_quiz === false || userDetails.blogExists) {
      return false;
    } else {
      const maxSum =
        userDetails.sumEarned +
        Number(questions.credits_per_correct_answer) * questions.quiz.quiz.questions.length;

      return maxSum <= Number(questions.max_earn) && displayQuiz;
    }
  }, [questions, userDetails, displayQuiz, blogs, props.blog_data]);

  const updateLocalStorage = (value) => {
    const blogIdExists = blogs.findIndex((blog) => blog.blog_id === props.id);

    const blogIdProps = {
      user_id,
      earned_from_quiz: value.correctPoints
    };

    if (blogIdExists !== -1) {
      blogs[blogIdExists] = {
        ...blogs[blogIdExists],
        ...blogIdProps
      };
    } else {
      blogs.push({
        blog_id: props.blog_data.id,
        ...blogIdProps
      });
    }

    localStorage.setItem('showQuiz', JSON.stringify(blogs));
  };

  const createQuiz = (value) => {
    const payload = {
      session_id: user_id,
      responses: answerArray(value)
    };
    setResults(value);
    quizService()
      .createQuiz(payload)
      .then(() => {
        updateLocalStorage(value);
        trackEvent('Blog Details', {
          action_category: 'Create quiz',
          action_outcome: 'success',
          interaction_element: 'screen',
          blog_detail_id: blog_id,
          source: 'sf_web'
        });
      })
      .catch(() => {
        updateLocalStorage(value);
        trackEvent('Blog Details', {
          action_category: 'Create quiz',
          action_outcome: 'fail',
          interaction_element: 'screen',
          blog_detail_id: blog_id,
          source: 'sf_web'
        });
      });
  };

  const handleClose = () => {
    setResults(undefined);
    setDisplayQuiz(false);
  };

  const handleOk = () => {
    setResults(undefined);
    setDisplayQuiz(false);
    if (results && results.numberOfCorrectAnswers === 0) {
      navigate('/blog');
    } else if (isUserLogged) {
      navigate('/profile/wallet', {
        state: {
          session_id: user_id
        }
      });
    } else {
      navigate('/login?view=register', {
        state: {
          session_id: user_id
        }
      });
    }
  };

  useEffect(() => {
    if (props && props.blog_data && props.blog_data.content) {
      addBlogCount();
    }
  }, [props.blog_data?.id]);

  return (
    <div data-testid="view-blog-details" className={'view-blog-details'}>
      <Seo title={t('web_title.blog')} />
      <div className={'flex_1'}>
        <div
          className={'header-bg'}
          style={{
            backgroundImage: `url('${Config.IMG_BASE_URL}${props.blog_data.image_cover}??h=600')`
          }}>
          <RWebShare
            data={{
              title: 'Snapfood Blog',
              url: `https://snapfood.al/blog/${props.blog_data['hash_id']}/${props.blog_data['slug']}`
            }}
            onClick={() => {}}>
            <img src={Svg_share} className={'share-btn'} />
          </RWebShare>
        </div>
        <div className={'flex_1 align-col-middle main ph4'}>
          <div className={'w100 blog-body'}>
            <Link to={ROUTES_NAMES.blog} className={'back-link'}>
              <ChevronLeft size={28} color={'#858490'} />
              {t('blog.go_back')}
            </Link>
            <div className={'align-row-start tags'}>
              {props.blog_data.categories && (
                <div className={'tag-text'}>
                  {props.blog_data.categories
                    .map((x) => (props.language == 'en' ? x.title : x.sq_title))
                    .join(', ')}
                </div>
              )}
            </div>
            <div className={'align-row-start title'}>
              <h1>{props.blog_data.title}</h1>
            </div>
            <div className={'align-row-start author'}>
              <div>{t('blog.written_by')}</div>
              <div>{props.blog_data.author}</div>
            </div>
            <div className={'align-row-start date'}>
              <div>{props.blog_data.created_at}</div>
            </div>
            <div className={'align-row-start'}>
              <div className={'content w100'}>{ReactHtmlParser(props.blog_data.content)}</div>
            </div>
            <div>
              {results && (
                <QuizCompletionModal
                  handleClose={handleClose}
                  open={Boolean(results)}
                  scores={results}
                  handleOk={handleOk}
                />
              )}
              <div className="custom-grid">
                <Subscribe />

                {showQuiz && questions && questions.quiz.quiz.questions.length > 0 && !results && (
                  <div>
                    <QuizComponent
                      handleSubmit={createQuiz}
                      questions={questions}
                      results={results}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

BlogDetails.propTypes = {
  language: PropTypes.string,
  blog_data: PropTypes.shape({
    title: PropTypes.string,
    image_cover: PropTypes.string,
    categories: PropTypes.array,
    author: PropTypes.string,
    created_at: PropTypes.string,
    slug: PropTypes.string,
    hash_id: PropTypes.string,
    content: PropTypes.string
  }),
  setBlogData: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  user: app.user,
  language: app.language,
  blog_data: app.blog_data
});

export default connect(mapStateToProps, { setBlogData })(BlogDetails);
