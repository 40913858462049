import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Pay_COD, Pay_Card, Pay_Apple, Pay_Paypal } from '../../../constants/config';
import PropTypes from 'prop-types';
import './index.css';

const methods = [Pay_COD, Pay_Paypal, Pay_Card, Pay_Apple];
const Cashbackitem = ({ data }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const onClick = (e) => {
    e.preventDefault();
    navigate(`/order/${data.order_id}`);
  };
  return (
    <div onClick={onClick} className={'cashback-item'}>
      <div className={'w100 align-row-start'} style={{ width: '100%', marginBottom: 4 }}>
        <div className={'title'}>{data.order_data != null ? data.order_data.title : ''}</div>
        <div style={{ flex: 1, alignItems: 'flex-end' }} />
        <div className={'price'}>{data.cashback_amount} L</div>
      </div>
      <div className={'w100 align-middle'}>
        <div className={'flex_1 align-col-middle'} style={{ alignItems: 'flex-start' }}>
          <div className={'pay_method_txt'}>
            {data.order_data != null &&
            data.order_data.payment_methods_id > 0 &&
            data.order_data.payment_methods_id < 5
              ? t(methods[data.order_data.payment_methods_id - 1])
              : ''}
          </div>
          <div className={'pay_method_txt'}>{data.order_created_at}</div>
        </div>
      </div>
    </div>
  );
};

function arePropsEqual(prevProps, nextProps) {
  return prevProps.id === nextProps.id;
}

Cashbackitem.propTypes = {
  id: PropTypes.number,
  data: PropTypes.shape({
    cashback_amount: PropTypes.number,
    order_created_at: PropTypes.string,
    order_data: PropTypes.shape({
      title: PropTypes.string,
      payment_methods_id: PropTypes.number
    }),
    order_id: PropTypes.number
  }),
  style: PropTypes.object
};

export default React.memo(Cashbackitem, arePropsEqual);
